@mixin backgroundOverlayClosed {
    background-color: rgba($black, 0.8);
    bottom: 0;
    content: "";
    height: 100vh;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transform: translateY(100%);
    transition: opacity 0.08s ease-in-out;
    width: 100vw;
    z-index: -2;
}

@mixin backgroundOverlayOpen {
    opacity: 1;
    pointer-events: all;
    z-index: 2;

    @media (max-width: $mobile-larger) {
        opacity: 0;
        pointer-events: none;
        z-index: -2;
    }
}

@mixin backgroundOverlayBody {
    overflow-y: hidden;

    @media (max-width: $mobile-larger) {
        overflow-y: auto;
    }
}

@mixin hideVisually {
    height: 1px;
    left: -10000px;
    overflow: hidden;
    position: absolute;
    transition: none;
    width: 1px;
}

@mixin skipLink {
    @include hideVisually;
    background-color: $white;
    border-bottom: 0;
    padding: 12px;
    text-align: center;
    text-decoration: underline;

    &:focus {
        left: 0;
        height: auto;
        width: 100%;
        z-index: 1000;
    }
}

@mixin skipLinkAnchor {
    @include hideVisually;
}

@mixin chevronFaStyles {
    content: "\f054";
    font-size: 12px;
    @include sharedIconStyles;
}

@mixin lockFaStyles {
    content: "\f30d";
    font-size: 14px;
    @include sharedIconStyles;
}

@mixin sharedIconStyles {
    font-family: $fontAwesome;
    position: relative;
    display: inline-flex;
    align-items: center;
}

// Nav Links
@mixin hoverNavLink {
    cursor: pointer;
}


@mixin focusNavLinkOutline {
    box-sizing: border-box;
    outline: none;
    // Unique to these focus outline designs, if reused move to variables.scss
    // $darkRed: #5A0000;
    // designs use $darkRed seems too dark for a 1px border recommendation to switch to $red
    border: $red solid 1px;
}

@mixin focusNavLinkTextColor {
    color: #282828;
}

// Override default link colors set in global css
@mixin focusedOrActiveLink {
    color: $red !important;
}

@mixin hoverControlElemBkgColor {
    background-color: $lightergrey;
}

@mixin defaultNavLink {
    color: $grey !important;
}

// Link Chevrons
@mixin activeChevronColor {
    stroke: $red;
}

@mixin defaultChevronColor {
    stroke: $grey;
}

@mixin selectChevronColor {
    stroke: $white;
}

@mixin flushBorder {
    // Border should be flush with parent container so must keep place for border when focus isn't applied
    border: 1px solid transparent;
    // Set transition to zero for border
    transition: border 0s;
}

@mixin desktopActiveNavBorder {
    opacity: 1;
    left: -16px;
    right: -16px;
}

@mixin mobileActiveNavBorder {
    opacity: 1;
    top: 0;
    bottom: 0;
}

@mixin navItemFocusStyles {
    @include flushBorder;

    &:focus-visible {
        transition-duration: 0s;
        @include focusNavLinkOutline;

        span {
            @include focusNavLinkTextColor;
        }
    }
}

@mixin hoverControlElement {
    &:hover {
        @include hoverControlElemBkgColor;

        svg path {
            @include activeChevronColor;
        }
    }
}

@mixin activeFaIcon {
    &::before {
        color: $red;
    }
}

//SVG
@mixin enable-svg-rotate {
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    animation: spin 2s linear infinite;
    display: block;
}

//Typography
@mixin headerLinkTypography {
    font-family: $sans-serif-medium;
    font-size: 13px;
    font-weight: 500;
    line-height: 28px;
}